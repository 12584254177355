/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.6.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "variables-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";;
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/repeater";
@import "custom/plugins/form-editors";
@import "custom/plugins/xeditable";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/file-manager";
@import "custom/pages/extras-pages";

@mixin border_left {
    border-left: 1px solid #d4d4d4;
    padding: 0 10px;
}
@mixin dark_color {
    color: #3F3F3F;
}
@mixin body_text_color {
    color: #565656;
}
@mixin black_text {
    color: #000000;
}
@mixin deep_grey {
    color: #7E8B93;
}
@mixin ticket_header {
    color: #5A6871;
    font-size: 12px;
}

@mixin ticket_detail {
    color: #041B29;
    font-size: 14px;
    padding: 6px 0;
    margin-bottom: 0;
}
@mixin afterBlock {
    position: absolute;
    top: 50%;
    right: 0;
    content: '';
    border-right: 1px solid #ddd;
    transform: translate(-20px, -50%);
}
@mixin svgSize{
    width: 18px;
    height: 18px;
    margin-right: 2px;
    @media screen and (max-width: 767px) {
        width: 14px;
        height: 14px;
    }
}
@mixin yellowBadge {
    background: #FFE1A6;
    padding: 2px 6px;
    border-radius: 15px;
    @include body_text_color;
    font-size: 12px;
}
.scroll_content.overall_tickets {
    overflow-y: scroll;
    min-height: 76vh;
    max-height: 76vh;
}
.page-content .scroll_rent_history {
    min-height: 76vh;
}
.complain_ticket.rent_history {
    max-height: 76vh;
}
@mixin deep_red{
    color: #ED3542
}
@mixin svgBigSize{
    width: 24px;
    height: 24px;
    margin-right: 2px;
    @media screen and (max-width: 767px) {
        width: 14px;
        height: 14px;
    }
}
@mixin messageImgBox {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    background: #FFEBEB;
    overflow: hidden;
    margin-bottom: 10px;
}
.tenant_dashboard {
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    .appartment_details {
        padding: 10px;
        .child_single_item {
            @include border_left;
            padding: 18px 10px;
            @media screen and (max-width: 992px) {
                &:first-child &:nth-child(2){
                    background-color: red;
                }
                // &:nth-child(-n+2) {
                //     grid-column: span 1; /* Each spans one column */
                // }
                // &:nth-child(n+3) {
                //     grid-column: span 2; /* Each spans two columns */
                // }
            }
            h5 {
                font-size: 1rem;
                @include dark_color;
            }
            svg {
                height: 18px;
                width: 18px;
                color: #1C1B1F;
            }
            p {
                font-size: $font-size-md;
                margin-bottom: 5px;
            }
            .rounded_icon {
                svg {
                    width: 16px;
                    border: 1.5px solid #565656;
                    border-radius: 50%;
                    margin-right: 5px;
                    height: 16px;
                    padding: 1px;
                }
            }
        }
        .two_grid_cols {
            grid-template-columns: 100px 432px;
        }
        .appartment_img {
            img {
                width: 80px;
                height: 100px;
                border-radius: 4px;
                object-fit: cover;
            }
        }
        
    }
    .two_main_grid_cols {
        grid-template-columns: 532px 1fr;
    }
    .four_grid_cols {
        grid-template-columns: repeat(4, 1fr);
    }
    .due_amt_block {
        background-color: #ED3542;
        color: #ffffff;
        padding: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23.5px;
        .rounded_icon {
            // p {
            //     @include black_text;
            // }
            svg {
                width: 24px;
                border: 1.5px solid white;
                border-radius: 50%;
                margin-right: 5px;
                height: 24px;
                padding: 1px;
            }
        }
    }
}
.payment_detail_block {
    .five_grid_cols {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }
    .total_payment {
        ul {
            background-color: #FFF1DB;
            .main_total {
                border-top: 1px solid #CFC4B1;
                padding-top: 10px;
            }
        }
    }
    .all_payment_blocks {
        .card {
            box-shadow: 2px 8px 15px 0px #0000001F;
            margin-bottom: 5px;
            border: 1px solid #C7D0DD;
            border-radius: 8px;
            padding: 5px 20px 5px 20px;
            h5 {
                margin: 0;
                font-size: 1rem; //font size: 16px;
            }
            span {
                font-size: $font-size-md; //font-size: 14px;
            }
            .single_payment_item {
                &:last-child{
                    text-align: right;
                }
                &:not(:first-child) {
                    @include border_left;
                }
                span{
                    margin-bottom: 6px;
                    display: block;
                }
            }
        }
    }
    .total_payment.card {
        ul li {
            span, h5 {
                font-size: 1rem;
                font-weight: 500;
                &.darker_text {
                    @include black_text;
                }
            }
        }
    }
    .pay_full_amt {
        button {
            border-radius: 7px;
            font-size: 18px;
            width: 176px;
            padding: 6px 12px;
        }
    }
}
.yellow_gradient_btn {
    background: linear-gradient(120deg, #FAA61B 0%, #FAA61B 81.96%);
    border: 1px solid #FAA61B !important;
    text-transform: capitalize !important;
    color: #fff !important;
}
.fade_yellow_gradient_btn {
    background-color: #e9c68e;;
    border: 1px solid #e9c68e;
}
.inner_page_header {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    @include body_text_color;
    @media screen and (max-width: 767px) {
        font-size: 16px;
        color: #000000;
    }
}
.complain_ticket {
    .single_ticket {
        background-color: #ffffff;
        box-shadow: 0px 3px 7px 0px #9FB2D00D, 0px 12px 12px 0px #9FB2D00A, 0px 27px 16px 0px #9FB2D008, 0px 48px 19px 0px #9FB2D003, 0px 75px 21px 0px #9FB2D000;
        margin-bottom: 10px;
        border-radius: 6px;
        .single_ticket_header {
            background-color: #FFF6E5;
            padding: 5px 8px;
            svg{
                @include svgSize;
            }
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
            .ticket_status {
                @include yellowBadge;
            }
            .ticket_id {
                @include deep_grey;
                font-size: 1rem;
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                }
                b {
                    @include body_text_color;
                }
            }
            &.single_message_header{

                .message_id {
                    @include deep_red;
                    font-size: 1rem;
                    font-weight: 400;
                    display: flex;
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                    }
                    b {
                        @include body_text_color;
                    }
                }
                .message_time {
                    color: #565656;
                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                    }
                }
                .message_id svg{
                    @include svgBigSize;
                    border-radius: 50%;
                    background-color: #ED3542;
                    padding: 3px;
                    margin-right: 5px;
                }
            }
            .ticket_price {
                @include body_text_color;
            }
            
        }
        .single_message {
            p {
                // border-bottom: 1px solid #E1E1E1;
                // padding: 10px;
                color: #565656;
                font-size: 16px;
            }
        }
        .single_ticket_body {
            grid-template-columns: 45% 35% 20%;
            padding: 11px 8px;
            @media screen and (max-width: 1280px) {
                grid-template-columns: 1fr auto 1fr;
                overflow-x: auto;
            }
            
            svg{
                @include svgSize;
            }
            .create_date, .resolved_by, .resolved_date {
                @include deep_grey;
                font-size: 15px;
                display: flex;
                @media screen and (max-width: 767px) {
                    font-size: 12px;
                    flex-direction: column;
                }
                b {
                    @include body_text_color;
                    font-weight: 400 !important;
                    @media screen and (max-width: 1280px) {
                        // width: 100px;
                        // white-space: nowrap;
                        // text-overflow: ellipsis;
                        // overflow: hidden;
                    }
                }
            }
            .responsive_word_break{
                margin-right: 2px;
            }
            &.single_ticket_four_body{
                grid-template-columns: 1fr 1fr 310px 1fr;

                @media screen and (max-width: 1280px) {
                    grid-template-columns: 1fr 1fr; 
                }
                .single_ticket_item {
                    position: relative;
                    .responsive_word_break, b{
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1280px){
                        padding: 10px;
                        &:last-child{
                            text-align: left !important;
                        }
                        &:nth-child(2)::after{
                            border-right: unset !important;     
                            }
                        &:nth-child(3),  &:nth-child(4){
                            border-top: 1px solid #ddd !important;
                        }
                        .responsive_word_break{
                            margin-bottom: 5px;
                            @media screen and (max-width: 375px){ 
                                font-size: 13px;
                            }
                        }
                        b{
                            font-size: 14px;
                            @media screen and (max-width: 375px){
                                font-size: 12px;
                            }
                        }
                    }
                    &:not(:last-child)::after  {
                        @include afterBlock;
                        transform: translate(0px, -50%);
                        height: 30px;
                        @media screen and (max-width: 767px){
                            top: 30px;
                        }
                    }
                    &:not(:first-child) {
                        padding-left: 10px;
                    }
                }
            }
            &.leaving_reason {
                .comment, .refund_note {
                    grid-template-columns: 2fr 1fr 1fr;
                    padding: 10px 0;
                    @media screen and (max-width: 767px){
                        grid-template-columns: unset;
                    }
                }
                .comment {
                    border-bottom: 1px solid #D4D4D4;
                }
            }
        }
        .single_ticket_footer {
            color: #565656;
            span{
                    padding-left: 5px;
            }
            ul {
                li {
                    margin: 0 5px 5px;
                    padding: 15px;
                    border-radius: 3px 0px 0px 0px;
                    font-size: 14px;
                    border: 1px solid #DFEAEF;
                    width: 175px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @media screen and (max-width: 425px) {
                        width: unset;
                        max-width: 140px;
                    }
                    @media screen and (max-width: 360px) {
                        max-width: 125px;
                        margin: 0 3px 5px;
                    }
                    .img_box {
                        text-align: center;
                        color: #00A0EA;
                        img {
                            @include messageImgBox;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .pdf_box{
                        @include messageImgBox;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            color: #ED3542;
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .img_name {
                        color: #00A0EA;
                        font-size: 12px;
                        text-align: center;
                        @media screen and (max-width: 767px) {
                            font-size: 10px;
                        }
                    }
                }
            }
            // @media screen and (max-width: 767px) {
            //     flex-direction: column;
            //     align-items: flex-start !important;
            // }
        }
    }
}

.two_one_track {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    // gap: 10px;
    .grid_item {
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
        position: relative;
        &.item1 {
            grid-row: 1;
            grid-column: 1;
            // border-right: 1px solid #ddd;
        }
        
        &.item2 {
            grid-row: 1;
            grid-column: 2;
        }
        
        &.item3 {
            grid-row: 2;
            grid-column: 1 / span 2;
        }
        
        &.item4 {
            grid-row: 3;
            grid-column: 1;
            // border-right: 1px solid #ddd;
        }
        
        &.item5 {
            grid-row: 3;
            grid-column: 2;
        }
    }
}
.ticket_detail_modal {
    .ticket_detail_header {
        background-color: #ED3542;
        color: #fff;
        padding: 8px 20px;
        h4 {
            color: #fff;
            margin-bottom: 0;
            font-size: 16px;
        }
        small {
            font-size: 14px;
        }
    }
    .allAttachments {
        .single_attachment{
            padding: 2px 15px;
            p.pdf_link {
                color: #00A0EA;
                font-size: 12px;
                font-weight: 400;
            }
            img {
                border-radius: 6px;
                margin-bottom: 5px;
            }
        }
    }
    .two_one_track {
        li {
            small{
                @include ticket_header;
            }
            h5 {
                @include ticket_detail;
            }
            &:first-child::after, &:nth-child(4):after {
                @include afterBlock;
                height: 50px;
            }
        }
    }
    .complain_details, .attachments {
        margin-bottom: 12px;
        h5{
            @include ticket_header;
            margin-bottom: 0;
        }
        p {
            @include ticket_detail;
        }
        .allAttachments {
            margin-top: 10px;
            overflow-x: auto;
        }
    }
}
.pg_vacant_request {
    display: flex;
    height: 75vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .pg_vacant_request_content {
        h5 {
            @include deep_grey;
        }
        p {
            @include body_text_color;
        }
    }
}
.reason_box {
    .ql-container {
        height: 100px;
    }
    h5 {
        color: #565656;
        font-size: 14px;
        margin-bottom: 4px;
    }
    .gen_leave_add_btn {
        border-top: 1px solid #C9C9C9;
        margin-top: 20px;
    }
}
.leaving_reason {
    .responsive_word_break {
        font-size: 14px;
        color: #565656;
        margin-bottom: 10px;
    }
    b {
        font-size: 16px;
        font-weight: 400;
        // margin-top: 8px;
        display: block;
    }
    .refund_note, .comment {
        .first_block {
            position: relative;
            &::after {
                @include afterBlock;
                height: 90px;
                transform: translate(0px, -50%);
                @media screen and (max-width: 767px){
                    border-right: unset;
                    // padding: 10px 0 !important;
                }
            }
            &.middle_inbetween_block {
                @media screen and (max-width: 767px){
                    padding-left: 0 !important;
                    padding-top: 12px;
                }
            }
            &:not(:last-child) {
                @media screen and (max-width: 767px){
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                }
            }
        }
        .second_block {
            .ticket_status {
                @include yellowBadge;
                display: inline-block;
            }
            @media screen and (max-width: 767px){
                padding: 10px 0 !important;
            }
        }
    }
}

.tenantDetailsDrawer {
    &.viewProDetails {
        .pro_person_mob, .designation {
            font-size: 14px;
        }
        .designation {
            border-bottom: 1px solid #ddd;
            padding: 5px 0 10px;
            width: 100%;
        }
    }
    // @include custom-border-bottom;
    .tenantDetailTitle {
        color: #636771;
        font-size: 14px;
        width: 52%;
    }
    .tenantDetailDesc {
        color: #37324A;
        font-size: 14px;
    }
    .tenantDetFullTitle {
        width: 35%;
    }
    .tenantDetHalfFullTitle {
        width: 25%;
    }
    .customSelectfilter {
        width: 150px;
    }
    .attachIcon {
        background: #ED3542;
        padding: 4px 4px 6px;
        border-radius: 5px;
        margin-right: 10px;
    }
    .uploadedFileName {
        color: #3569ED;
        text-decoration: underline;
    }
    .pro_person_name {
        color: #38324A;
        font-size: 20px;
        font-weight: 700;
        // margin-bottom: 10px;
    }
    .pro_person_mob {
        color: #5E5873;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
    }
}

// .profileViewer {
    // position: relative;
    // display: flex;
    // flex-direction: column;
    //width: 100%;
    // width: 700px;
    // height: 500;
    // height: 100%;
        // color: var(--bs-modal-color);
        // pointer-events: auto;
        // background-color: var(--bs-modal-bg);
        // background-clip: padding-box;
        // border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        // border-radius: var(--bs-modal-border-radius);
        // outline: 0;
// }

 .viewProDetails {
    .image-placeholder {
        position: relative;
        overflow: hidden;
        &:hover .editIconBox {
            display: block;
        }
    }
    .editIconBox {
        background-color: rgba(216, 216, 216, 0.749);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1;
    }
    .editIconBox svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
        transition: all 0.5s ease-in-out;

    }
    .attachedDocs img {
        width: 100%;
    }
}

.custom-image-upload {
    display: flex;
    // align-items: center;
    // margin-bottom: 20px;
    .image-placeholder {
        height: 100px;
        width: 100px;
        border-radius: 7px;
        background: rgba(182, 178, 178, 0.2);
        text-align: center;
        line-height: 50px;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            overflow: hidden;
            object-fit: contain;
        }
    }
    label{
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
    }
}

// responive css
@media screen and (max-width: 1280px) {
    .appartment_details{
        display: flex !important;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 767px) {
    .tenant_dashboard{
        .appartment_details{
            .four_grid_cols{
                grid-template-columns: 1fr 1fr;
                width: 100%;
                .child_single_item {
                    border-top: 1px solid #d4d4d4;
                    padding: 10px;
                    &:nth-child(odd){
                        border-left: unset;
                    }
                }
            }
            .two_grid_cols{
                grid-template-columns: 90px 1fr !important;
                margin-bottom: 10px;
            }
            .four_grid_cols .child_single_item, .two_grid_cols.single_item{
                h5 {
                    font-size: $font-size-md;
                    margin-bottom: 0;
                }
                p {
                    font-size: 0.75rem;
                }
                svg {
                    width: 18px;
                }
            }
        }
        .due_amt_block {
            font-size: $font-size-md;
            padding: 9px;
            .rounded_icon svg {
                width: 18px;
                height: 18px;
            }
        }
    }
    .payment_detail_block {
        .five_grid_cols {
            grid-template-columns: auto auto auto auto auto;
            overflow-x: auto;
        }
        .block_header {
            font-size: 1rem; //font size: 16px;
        }
        .all_payment_blocks {
            .card {
                padding: 0;
                span {
                    font-size: 0.65rem;
                }
                h5 {
                    font-size: 0.775rem;
                }
                .single_payment_item{
                    .truncate_month {
                        width: 30px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &:last-child {
                        text-align: left;
                    }
                }
            }
            .total_payment {
                ul li {
                    h5, span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.initial_letter {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:plum;
  vertical-align:middle;
  margin-bottom: 6px;
  margin-right:1px;
  color:white;
}
.tenanatProfileDetailDrawer {
    max-width: 700px;
    // margin: auto;
    @media screen and (max-width: 767px) {
        max-width: unset;
        // margin: unset;
    }
}

.table_card_holder {
    .table {
        thead {
            th {
                background: #ED3542;
                color: #fff;
                border: none;
                white-space: nowrap;
            }
        }
        tbody {
            & + thead {
                display: none;
            }
            .notifyAction {
                span {
                    font-size: 20px;
                    color: #ed3542;
                }
            }
            & .avatar-sm {
                margin-right: 5px;
                height: 40px;
                width: 40px;
                object-fit: contain;
                border-radius: 50%;
            }
            & .unread {
                td {
                    background-color: rgba(255, 0, 0, 0.08);
                }
            }
        }
    }
}
.numbers_pagination_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .number_holder {
        display: flex;
        align-items: center;

        .MuiInputBase-root {
            padding: 0 18px 0 0;
            input {
                border: none;
                padding: 0 !important;
            }
        }
        fieldset {
            border: none;
        }
    }
}

.main_message {
    @include text-truncate;
    max-width: 350px;
}
.delete_action_col div:nth-child(2) {
    display: none;
}
.msgAttachment_listing {
    color: #565656;
    span{
            padding-left: 5px;
    }
    ul {
        li {
            margin: 0 5px 5px;
            padding: 15px;
            border-radius: 3px 0px 0px 0px;
            font-size: 14px;
            // border: 1px solid #DFEAEF;
            width: 175px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-child {
                margin-left: 0;
            }
            @media screen and (max-width: 425px) {
                width: unset;
                max-width: 140px;
            }
            @media screen and (max-width: 360px) {
                max-width: 125px;
                margin: 0 3px 5px;
            }
            .img_box {
                text-align: center;
                color: #00A0EA;
                img {
                    @include messageImgBox;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .pdf_box{
                @include messageImgBox;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    color: #ED3542;
                    width: 24px;
                    height: 26px;
                }
            }
            .img_name {
                color: #00A0EA;
                font-size: 12px;
                text-align: center;
                @media screen and (max-width: 767px) {
                    font-size: 10px;
                }
            }
        }
    }
}
.message_recipient {
    ul {
        width: 'fit-content';
        li {
            padding: 6px 12px;
            font-size: 12px;
            color: #000;
        }
    }
}
.main_img_block {
    .tenantDetailDesc {
        color: #38324A;
        font-size: 14px;
        font-weight: 700;
    }
}
.msg_title {
    font-size: 12px;
    color: #858890;
    margin-bottom: 10px;
}
.full_msg {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.56px;
}
.time_date {
    font-size: 14px;
    color: #686474;
}
.browse_attachment_btn {
    button {
        overflow: hidden;
        margin-left: 0 !important;
        input {
            background: red;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
}
.write_msg .quill {
    .ql-editor {
        height: 100px;
    }
}
.latest_activities {
    .inner_pg_content{
        // max-height: 50vh;
        overflow-x: auto;
        li {
            margin-bottom: 15px;
            position: relative;
            .named_picture {
                background: #FFE0E0;
                border-radius: 50%;
                padding: 5px;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:not(:last-child){
                &::after {
                    position: absolute;
                    border-left: 2px dotted #fb0039;
                    height: 30px;
                    content: '';
                    left: 20px;
                    bottom: -30px;
                }
            }
            .activity_status_name {
                color: #fb0039;
            }
            .activity_user{
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 0;
                margin-right: 5px;
            }
        }
    }
}
.notification {
    display: flex;
    align-items: center;
    padding: 3px;
    background-color: #fff;
    margin-bottom: 5px;
}

.notification-icon {
    flex: 0 0 auto;
    margin-right: 10px;
}

.notification-icon i {
    font-size: 24px;
    color: #ffc107;
}

.notification-content {
    flex: 1 1 auto;
}

.notification-subject {
    margin: 0;
    font-size: 18px;
}

.notification-body {
    margin: 0;
    font-size: 14px;
    color: #555;
}

.tenantDetailTitle {
    color: #636771;
    font-size: 14px;
    width: 52%;
}

.tenantDetailDesc {
    color: #37324A;
    font-size: 14px;
}

.amenityImagesSlider {
    .slick-arrow {
        position: absolute;
        z-index: 9;
        top: 50%;
        background-color: #ffffff;
        padding: 15px 10px;
        &.slick-prev {
            // left: 0;
            display: none !important;
        }
        &.slick-next {
            // right: 0;
            display: none !important;
        }
        &.custom-next{
            right: 10px;
        }
        &.custom-prev{
            left: 10px;
        }
    }
}

.crossIcon {
    // right: -10px;
    // top: -10px;
    right: 3px;
    top: 3px;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 3px #939393;
    z-index: 9;
}

.popup_block {
    .MuiPaper-root {
        border-radius: 6px 0px 6px 6px;
        box-shadow: unset;
    }
    .popContent {
        background-color: #000000;
        color: #ffffff;
        p{ 
            font-size: 14px;
            margin-bottom: 1px;
        }
          @media screen and (max-width: 768px) {
             width: 200px
          }
    }
}


.ql-editor{
    padding: 0;
}
.ql-hidden {
    display: none;
}
.offcanvas-header {
    padding-bottom: 0;
}

.view-menu-button {
    color: #0752C3 !important;
    text-decoration: underline !important;
    cursor: pointer;
}


.review_replies {
    padding-left: 10px;
    padding: 0.5rem;
    .edit-button {
        margin-left: -2.2rem;
    }
    &:nth-child(odd) {
        border-left: 4px solid #FAA61B;
        background-color: var(--#{$prefix}sidebar-menu-item-active-bg-color);
    }
    &:nth-child(even) {
        border-left: 4px solid #1b8efa;
        background-color: #e9f2fc;
    }
}

.hide-scroll {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.footer_contact_links {
    nav {
        display: grid;
        grid-template-columns: auto auto auto;
    }
}