// Variables
$font-size-base: 1rem;
$font-size-heading: 32px;
$body_font_lg: 18px;
$link-color: #0752C3;
$link-hover-color: #0056b3;
$font-weight-bolder: 700;
$font-weight-bold: 600;
$font-weight-medium: 500;
$theme-red-color: #ED3542;
$yellow_color: linear-gradient(45deg, #faa61b, #faa61b);

@mixin amenities {

    //margin-bottom: 15px;
    ul {
        margin-bottom: 0;
        padding: 6px 0;
        flex-wrap: wrap;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: #D0D4D8;

        @media screen and (max-width: 992px) {
            white-space: nowrap;
            overflow-x: auto;
            flex-wrap: nowrap !important;
            margin-bottom: 20px;
        }

        @media screen and (max-width: 480px) {
            margin-left: 0 !important;
        }

        li {
            padding: 0px 10px !important;
            color: #282828;
            font-weight: 500;
            font-size: 12px;

            // border-color: #D0D4D8;
            // border-width: 1px 0 1px 0;
            // border-style: solid;
            @media screen and (max-width: 992px) {
                padding: 5px 8px !important;
            }

            &:not(:last-child) {
                border-right: 1px solid #D0D4D8;
            }

            &:first-child {
                padding-left: 0 !important;

                @media screen and (max-width: 992px) {
                    padding-left: 8px;
                }

                @media screen and (max-width: 480px) {
                    padding-left: 5px;
                    margin-right: 0px;
                }
            }

            svg {
                height: 20px;
                width: 20px;
                color: #565656;

                @media screen and (max-width: 480px) {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
}

@mixin more_tag {
    font-size: 16px !important;
    padding: unset !important;
    color: #5A6871 !important;
    margin-right: unset !important;
    min-width: 40px;
    background-color: unset !important;
    border: unset !important;
    line-height: 26px !important;

    @media screen and (max-width: 480px) {
        font-size: 12px !important;
    }
}

@mixin red_bordered_btn {
    color: #ED3542;
    border: 1px solid #ED3542;
    text-transform: capitalize;

    svg {
        width: 20px;
        margin-right: 10px;
    }
}

@mixin room_detail_svg {
    width: 16px;
    color: #5A6871;
}

// Applying Variables
html {
    font-size: $font-size-base;
}

body {
    color: #3F3F3F;
}

a {
    color: $link-color !important;
    text-decoration: underline !important;

    &:hover {
        color: $link-hover-color;
    }
}

.body_font_md {
    font-size: 16px;

    @media screen and (max-width: 992px) {
        font-size: 14px;
    }
}

.body_font_sm {
    // font-size: 12px;
    font-size: 14px;
}

.font_bold {
    font-weight: $font-weight-bold;
}

.font_black {
    color: $black;
}

.theme_color {
    color: $theme-red-color !important;
}

.cursor_pointer {
    cursor: pointer;
}

.icon_input {
    position: relative;

    .MuiFormControl-root {
        position: relative;

        input {
            padding-right: 35px;
        }
    }

    svg {
        position: absolute;
        top: 40px;
        right: 10px;
        font-size: 20px;
        color: #bfbfbf;
    }
}

// Headings
.page_sub_heading {
    .available_sub_heading {
        color: #000000;
        font-size: 22px; 
        font-weight: 600; 
    }
    .filters {
        button {
            border: 1px solid #737373;
            border-radius: 22px;
            color: #333333;
            text-transform: capitalize;
            position: relative;
            font-size: 16px;
            padding: 5px 10px;

            @media screen and (max-width: 992px) {
                border: unset;
                padding: 0;
                min-width: unset;
            }

            span.text {
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }

            .MuiBadge-root {
                @media screen and (max-width: 991px) {
                    top: 4px;
                    right: 8px;
                }
            }
        }

        .filterBy {
            .MuiBadge-badge {
                font-size: 10px;
                height: 15px;
                min-width: 15px;
                padding: unset;
                background-color: #fb0039;
            }
        }
    }

    h2 {
        color: #000000;
        font-size: 26px;
        font-weight: $font-weight-bold;
        text-align: center;
        margin-bottom: 20px;

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }

        @media screen and (max-width: 640px) {
            font-size: 16px;
        }
    }

    button {
        svg {
            width: 21px;
            height: 21px;
            margin-right: 5px;
        }
    }

    &.schedule_visit_heading {
        h2 {
            text-align: left;
            font-weight: normal !important;
        }
    }
}

.page_header {
    h2 {
        font-size: 18px;
        color: #3F3F3F;
        font-weight: 600;

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }

        @media screen and (max-width: 640px) {
            font-size: 16px;
        }
    }
}

.header_main {
    .main_logo {
        img {
            cursor: pointer;
            max-width: 100%;

            @media screen and (max-width: 598px) {
                // width: 32px;
                height: 32px;
                margin-right: 10px;
            }
        }
    }
    .enlist_border {
        border-right: 1px solid #d2d3d3;
        margin-right: 20px;
        padding-right: 15px;

        @media screen and (max-width: 598px) {
            border-right: none;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .sign_in_button {
        padding: 5px 8px;
        min-width: unset;
        font-weight: 600;

        svg {
            margin-right: unset !important;
        }

        span {
            font-weight: 600;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}

// button starts
button.theme_button {
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: capitalize;
    display: inline-block;
    box-shadow: unset !important;
    padding: 8px 24px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $theme-red-color;
        border-radius: 20px;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #da1623;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }

    &:hover {
        color: #fff;

        &:before {
            width: 100%;
        }
    }

    svg {
        width: 20px;
    }
}

.header {
    background: #fff;
    padding: 15px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 15px;

    .enlist_prop {
        border: 1px solid #ED3542;
        border-radius: 20px;
        // margin-right: 30px;
        // border-right: 1px solid #D2D3D3;
        padding-right: 30px;
        cursor: pointer;
        color: #ED3542;
        text-align: center;
        padding: 8px 8px;

        @media screen and (max-width: 480px) {
            // padding-right: 0;
            // border-right: unset;
            margin-right: 10px;
        }
    }
}

main {
    min-height: calc(100vh - 310px);
}

// footer starts
.main_footer {
    background-color: #000000;
    margin-top: 30px;

    .footer_upper_content,
    .footer_below_content {
        padding: 20px 10px 20px 10px;
        color: #ffffff;

        nav {
            a {
                font-size: $font-size-base;
                color: #ffffff !important;
                margin: 0 10px;
                text-decoration: unset !important;
            }
        }
    }

    .footer_upper_content {
        .footer_contact {
            .contact_item span {
                font-weight: $font-weight-bolder;
                font-size: $font-size-base;
            }
        }
    }

    .footer_below_content {
        border-top: 1px solid #3F3F3F;
    }
}


// landing page starts here
.landing_pg {
    .home_search_banner {
        .main_banner_content {
            background-image: url('../images/home_banner.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 15px 0;
            color: white;
            text-align: center;
            display: grid;

            .search_bar_broadness {
                width: 855px;

                .autocomplete-container {
                    &>div {
                        &>div {
                            padding: 3px 0;
                            width: calc(100% - 100px);
                            border: unset;
                            box-shadow: unset;
                        }
                    }

                    &>div:not(:last-child) {
                        border-color: hsl(0, 0%, 80%);
                        border-radius: 4px;
                        border-style: solid;
                        border-width: 1px;
                        background: #fff;
                    }
                }

                #react-select-2-placeholder {
                    margin-left: 2px;

                    &+div {
                        padding-left: 20px;
                    }
                }

                #react-select-2-live-region {
                    &+span+div {
                        &>div {
                            &>div {
                                padding-left: 28px;
                                width: calc(100% - 60px);
                            }
                        }
                    }
                }

                MuiChip-root {
                    height: 25px;
                }

                @media screen and (max-width: 1280px) {
                    width: 80vw;
                    max-width: 100%;
                }
                .testimonial_slider_card {
                 @media screen and (max-width: 580px) {   
                    min-width: 100%;
                }
            }
            }

            h2 {
                margin-bottom: 0;

                span {
                    color: #fff;
                    font-size: 22px !important;
                    display: inline-block;

                    @media screen and (max-width: 460px) {
                        font-size: 16px !important;
                    }
                }
            }

            .inner_header {
                font-size: $body_font_lg;
                margin-bottom: 5px;

                @media screen and (max-width: 992px) {
                    font-size: 16px;
                }

                @media screen and (max-width: 640px) {
                    font-size: 12px;
                }
            }

            .main_banner_search {
                margin: 0 auto;

                .MuiAutocomplete-root {
                    background-color: #fff;
                    border-radius: 6px;

                    // @media screen and (max-width: 640px) {
                    //     border-radius: 30px;
                    // }

                    .MuiInputBase-root {
                        padding: 2px 15px !important;
                        color: #4C4C4C;

                        @media screen and (max-width: 460px) {
                            padding: 2px 15px !important;
                            font-size: 14px;
                        }

                        input {
                            &::placeholder {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }

    .home_info_cards {
        display: flex;
        gap: 18px;
        padding: 16px 8px;
        .testimonial_slider_card{
            // @media screen and (max-width: 580px) {
            //     min-width: unset;
            // }
        }
        @media screen and (max-width: 991px) {
            white-space: nowrap;
            overflow-x: auto;
            gap: 8px;
            padding: 5px 8px;
            margin-bottom: 10px;
        }

        .single_card {
            background-color: #FFFAF1;
            text-align: center;
            padding: 12px 20px;
            // max-width: 273px;
            width: 100%;
            // margin-bottom: 15px; 
            border-radius: 8px;
            transition: all 0.3s ease-in-out;
            cursor: default;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #fef5e6;
                box-shadow: 0 2px 6px #efe0b8;
            }

            @media screen and (max-width: 860px) {
                margin-bottom: 0;
            }

            @media screen and (max-width: 580px) {
                max-width: 100%;
                min-width: 100%;
            }

            .info_bg {
                height: 38px;
                width: 38px;
                background: #EEE4D1;
                border-radius: 50%;
                // line-height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: unset;
                margin-right: 15px;

                img {
                    // width: 24px;
                    height: 24px;
                }
            }

            h4 {
                color: #565656;
                font-size: 14px;
                text-align: left;
                margin-bottom: 0;

                @media screen and (max-width: 640px) {
                    font-size: 12px;
                }
            }
        }
        .slider_card {
         
            @media screen and (max-width: 580px) {
                min-width: 100% !important;
            }
            @media screen and (max-width: 768px) {
                min-width: 40%;
            }
            @media screen and (max-width: 880px) {
                min-width: 45%;
            }

            .slider_card_wrap {
                @media screen and (max-width: 880px) {
                   text-wrap: wrap;
                }
            }
        }
    }

    .available_rooms_listing {
        .page_sub_heading {
            h2 {
                text-align: left;
                // font-size: 28px;

                @media screen and (max-width: 991px) {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                @media screen and (max-width: 640px) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
        }

        .property_listing {
            margin-bottom: 35px;

            .property_card {
                overflow: unset;
                box-shadow: 0 292px 82px 0 rgba(0, 0, 0, 0), 0 187px 75px 0 rgba(0, 0, 0, 0.01), 0 105px 63px 0 rgba(0, 0, 0, 0.03), 0 47px 47px 0 rgba(0, 0, 0, 0.04), 0 12px 26px 0 rgba(0, 0, 0, 0.05);
                border: 1px solid #E9E9E9;
                border-radius: 10px;
                // padding: 15px;
                padding: 10px;
                margin-bottom: 15px;

                .single_property_card {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 35px;
                    position: relative;
                    .bedroom_details {
                        border-radius: 8px;
                        border: 1px solid #C0C7D5;
                        padding: 2px 15px;
                        background: #F1F5FD;
                        li {
                            width: 50%;
                            padding: 5px;
                            font-size: 16px;
                            position: relative;
                            @media screen and (max-width: '480px') {
                                padding: 5px 0;
                            }
                            &:nth-child(1){
                                border-bottom: 1px solid #C0C7D5;
                                @media screen and (max-width: 420px) {
                                    border-bottom: unset;
                                }
                            }
                            &:nth-child(2) {
                                border-bottom: 1px solid #C0C7D5;
                                @media screen and (max-width: 420px) {
                                    border-bottom: unset;
                                }
                            }
                            &:nth-child(even) {
                                padding-left: 15px;
                                @media screen and (max-width: 420px) {
                                    padding-left: unset;
                                }
                            }
                            &:nth-child(odd)::after {
                                position: absolute;
                                right: 0;
                                height: 24px;
                                content: '';
                                background: #C0C7D5;
                                width: 1px;
                                @media screen and (max-width: 420px) {
                                    display: none;
                                }
                            }
                            svg {
                                @include room_detail_svg();
                            }
                            @media screen and (max-width: 420px) {
                                width: 100%;
                            }
                        }
                    }

                    .property_img,
                    .price_detail_card {
                        width: 50%;
                    }

                    .price_detail_card {
                        border: 1px solid #FFDCA2;
                        // max-width: 520px;
                        // margin-left: 30px;
                        border-radius: 8px;
                        box-shadow: 0 169px 47px 0 rgba(250, 166, 27, 0), 0 108px 43px 0 rgba(250, 166, 27, 0.01), 0 61px 36px 0 rgba(250, 166, 27, 0.03), 0 27px 27px 0 rgba(250, 166, 27, 0.04), 0 7px 15px 0 rgba(250, 166, 27, 0.05);

                        @media screen and (max-width:767px) {
                            max-width: 100%;
                            width: 100%;
                            margin-left: unset;
                        }

                        .payment_area {
                            padding: 8px 15px;
                        }

                        .payment_btn {
                            button {
                                width: 100%;
                                font-weight: bold;
                            }
                        }
                        .coupon_inputfield div input {
                           padding: 12.5px 14px;
                        }
                        .remove_code_button {
                            border: 1px solid #C4CDDE;
                            background: transparent;
                            color: #6d6e6f;
                            padding: 4px 12px;
                            border-radius: 4px;
                        }
                        .price_detail_header {
                            background: #FAA61B;
                            color: #fff;
                            font-size: 18px;
                            font-weight: 500;
                            border-radius: 8px 8px 0 0;
                        }

                        .price_detail_body {
                            margin-bottom: 0;
                            list-style-type: none;

                            li {
                                margin: 12px 0;
                                font-size: 16px;
                                color: #3F3F3F;

                                &.total_amt {
                                    border-top: 1px solid #D5D5D5;
                                    border-bottom: 1px solid #D5D5D5;
                                    padding: 15px 0;
                                    margin-bottom: 0;

                                    .total_payable_amount {
                                        h4 {
                                            @media screen and (max-width:992px) {
                                                font-size: 16px;
                                            }
                                        }
                                        span{
                                            font-size: 14px;
                                        }
                                    }
                                }

                                span.price_amt {
                                    width: 170px;
                                    text-align: right;
                                }
                            }
                        }
                    }

                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                    }

                    .slider-container,
                    .content-container {
                        flex: 1;
                        min-width: 300px;
                        position: relative;

                        @media (max-width: 768px) {
                            min-width: 100%;
                        }
                    }

                    .content-container {
                        flex: 2;

                        .main_icon {
                            // width: 16px;
                            height: 20px;
                            color: #565656;
                            margin-right: 5px;

                            @media screen and (max-width: 992px) {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        .property_header {

                            // margin-bottom: 10px;
                            @media screen and (max-width: 992px) {
                                flex-wrap: wrap;
                            }

                            .MuiRating-root {
                                font-size: 20px;
                            }

                            .header_btns {
                                button {
                                    padding: 5px;
                                    font-weight: 600;
                                    border-radius: 5px;
                                    border: none;

                                    &::after,
                                    &::before {
                                        border-radius: 5px;
                                    }

                                    @media screen and (max-width: 992px) {
                                        padding: 2px 6px;
                                        font-size: 14px;
                                    }

                                    @media screen and (max-width: 460px) {
                                        font-size: 12px;
                                    }
                                }

                                @media screen and (max-width: 992px) {
                                    width: 100%;
                                }
                            }

                            position: relative;

                            h2 {
                                font-size: 18px;
                                margin-bottom: 5px;
                                font-weight: 600;

                                @media screen and (max-width: 640px) {
                                    font-size: 16px;
                                }
                            }

                            .ribbons-wrapper {
                                display: flex;
                                flex-direction: row-reverse;

                                .ribbon {
                                    display: flex;
                                    align-items: center;
                                    width: calc(100% + 50px);
                                    height: 32px;
                                    text-align: center;
                                    background: #0A81EF;
                                    position: relative;
                                    border-radius: 30px 0 0 30px;
                                    color: #fff;
                                    padding: 5px 20px;
                                    right: -21px;
                                    font-weight: bold;

                                    @media screen and (max-width: 460px) {
                                        padding: 5px 10px;
                                        font-size: 12px;
                                    }

                                    svg {
                                        @media screen and (max-width: 460px) {
                                            width: 20px;
                                        }
                                    }
                                }

                                .ribbon:before,
                                .ribbon:after {
                                    content: "";
                                    position: absolute;
                                }

                                .ribbon:before {
                                    height: 0;
                                    width: 0;
                                    bottom: -10px;
                                    left: 0;
                                }

                                .ribbon:after {
                                    height: 0;
                                    width: 0;
                                    right: 0;
                                    bottom: -10px;
                                    border-top: 10px solid #015098;
                                    border-right: 10px solid transparent;
                                }
                            }

                            @media (min-width: 500px) {
                                .ribbon {
                                    width: 48%;
                                }
                            }
                        }

                        .property_content {
                            .main_location {
                                @media screen and (max-width: 992px) {
                                    margin-top: 10px;
                                }
                            }

                            .tags_block {

                                // margin-bottom: 15px;
                                @media screen and (max-width: 992px) {
                                    flex-direction: column;
                                    align-items: flex-start !important;
                                }

                                .tag_heading {

                                    // width: 22%;
                                    @media screen and (max-width: 992px) {
                                        width: 100%;
                                    }
                                }

                                ul {

                                    // flex-wrap: wrap;
                                    // width: 82%;
                                    @media screen and (max-width: 992px) {
                                        margin-top: 10px;
                                        white-space: nowrap;
                                        overflow-x: auto;
                                        flex-wrap: nowrap;
                                        width: 100%;
                                    }

                                    li {
                                        color: $theme-red-color;
                                        border: 1px solid $theme-red-color;
                                        margin: 0 5px;
                                        padding: 2px 8px;
                                        background: #FFF1F2;
                                        border-radius: 3px;
                                        line-height: 20px;
                                        font-size: 12px;

                                        // font-weight: 600;
                                        // margin-bottom: 10px;
                                        @media screen and (max-width: 992px) {
                                            margin-bottom: 5px;
                                        }
                                    }
                                }
                            }

                            .amenities {
                                @include amenities
                            }

                            .bed_type {
                                margin-bottom: 10px;

                                @media screen and (max-width: 992px) {
                                    flex-direction: column;
                                }
                                @media screen and (min-device-width: 991px) and (max-device-width: 1950px) {
                                    margin-bottom: 50px; 
                                }

                                .bed_type_header {
                                    // width: 120px;
                                    display: flex;
                                    align-items: center;

                                    @media screen and (max-width: 991px) {
                                        margin-bottom: 10px;
                                    }
                                }

                                svg {
                                    // width: 16px;
                                    height: 20px;
                                    margin-right: 5px;
                                }

                                ul {
                                    @media screen and (max-width: 992px) {
                                        white-space: nowrap;
                                        overflow-x: auto;
                                        width: 100%;
                                    }

                                    li {
                                        font-size: 12px;
                                        background: #ddd;
                                        padding: 2px 8px;
                                        margin-right: 5px;
                                        border-radius: 2px;
                                        font-weight: 500;
                                        margin-bottom: 5px;

                                        &.primary_block {
                                            color: #09368D;
                                            background: #E0ECFE;
                                        }

                                        &.default_block {
                                            color: #3E3E3E;
                                            background: #EAEAEA;
                                        }
                                    }
                                }
                            }

                            .amount_block {
                                @media screen and (min-width: 991px) {
                                    position: absolute;
                                    width: 100%;
                                    bottom: 0;
                                }

                                @media screen and (max-width: 860px) {
                                    flex-direction: column;
                                    display: flex;
                                    width: 100%;
                                }

                                .amt {
                                    font-size: $body_font_lg;
                                    color: #3F3F3F;

                                    @media screen and (max-width: 860px) {
                                        width: 100%;
                                    }

                                    .main_rate {
                                        font-size: 25px;
                                        font-weight: bold;
                                        color: #565656;

                                        @media screen and (max-width: 992px) {
                                            font-size: 18px;
                                            margin-right: 2px;
                                        }
                                    }

                                    @media screen and (max-width: 992px) {
                                        font-size: 13px;
                                        line-height: 13px;
                                    }
                                }

                                .schedule_btns {
                                    @media screen and (max-width: 860px) {
                                        display: flex;
                                        width: 100%;
                                    }

                                    @media screen and (max-width: 480px) {
                                        flex-direction: column;
                                    }

                                    svg {
                                        width: 16px;
                                        margin-right: 5px;

                                        @media screen and (max-width: 992px) {
                                            width: 20px;
                                        }
                                    }

                                    button {

                                        @media screen and (max-width: 992px) {
                                            font-size: 12px;
                                        }

                                        @media screen and (max-width: 860px) {
                                            margin: 10px 10px 0 0;
                                        }

                                        @media screen and (max-width: 480px) {
                                            margin: 10px 10px 0 0;
                                        }
                                    }

                                    .red_bordered_btn {
                                        @include red_bordered_btn;
                                    }
                                }

                                .property_common_btn {
                                    border-radius: 30px;
                                    text-transform: capitalize;
                                    font-weight: 500;
                                    padding: 2px 12px;
                                    box-sizing: border-box;

                                    @media screen and (max-width: 992px) {
                                        padding: 4px 8px;
                                    }
                                }

                                .red_bordered_btn {
                                    @include red_bordered_btn;
                                }

                                .yellow_btn {
                                    background: $yellow_color;
                                    color: #fff;
                                    margin-left: 10px;
                                    border: 1px solid #faa61b;

                                    @media screen and (max-width: 992px) {
                                        font-size: 12px;
                                    }
                                }

                                @media screen and (max-width: 540px) {
                                    flex-direction: column;
                                    align-items: flex-start !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .testimonial_section {
        background: linear-gradient(120.07deg, rgba(237, 53, 66, 0.15) 0%, rgba(250, 166, 27, 0.15) 81.96%);
        padding: 10px 20px 10px 20px;

        .slick-slide {
            padding: 0 15px;
        }

        .single_testimonial {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            border-radius: 8px;

            img {
                @media screen and (max-width: 991px) {
                    width: 20px;
                }
            }
        }

        .avatar {
            width: 48px;
            height: 48px;
            margin-bottom: 10px;
            background: linear-gradient(120.07deg, $theme-red-color 0%, #FAA61B 81.96%);
            padding: 2px;
        }

        .name {
            font-weight: $font-weight-medium;
            font-size: 18px;
            margin-bottom: 15px;

            @media screen and (max-width: 991px) {
                font-size: 14px;
            }
        }

        .client_name {
            font-weight: 600;
            font-size: 16px;
        }

        .MuiCardContent-root {
            @media screen and (max-width: 991px) {
                flex-direction: column;
                align-items: center;
            }
        }

    }
}

.popButtons {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;

    .comm_popup_btn {
        text-transform: capitalize;
        font-size: 16px;
        padding: 8px 15px;
        border: 1px solid transparent;
        border-radius: 30px;
        width: 48%;

        &.red_btn {
            background: #ED3542;
            color: #ffffff;
        }

        &.yellow_btn {
            background: #faa61b;
            color: #ffffff;
        }
    }
}

.MuiPopover-root .MuiPaper-root.MuiPaper-rounded {
    border: 1px solid #737373;
    border-radius: 10px;
    overflow: hidden;
    // top: 70px !important;
    box-shadow: 0 114px 32px 0 rgba(0, 0, 0, 0), 0 73px 29px 0 rgba(0, 0, 0, 0.01), 0 41px 25px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.09), 0 5px 10px 0 rgba(0, 0, 0, 0.10);
}

// details page starts
.slick-track {
    margin-left: 0 !important;
}

.detail_pg {
    padding-bottom: 20px;
    

    .slider-container.property_img {
        margin-bottom: 10px;

        .slick-slide {
            padding: 0 6px;
        }

        .slick-prev::before,
        .slick-next::before {
            display: none;
        }

        svg {
            background: #fff;
            width: 48px;
            height: 48px;
            border-radius: 3px;
        }

        &.property_desc_slider {
            margin-bottom: unset;
            width: 100%;
            display: flex;
            align-items: flex-start;
            .slick-slider {
                width: 75%;
                img {
                    width: 100%;
                }
                .slick-slide {
                    padding: unset;
                }
            }
            .slider_thumbnail{
                width: 30%;
                height: auto;
                overflow: hidden;
                .slick-slide {
                    &:first-child{
                        padding-left: 5px !important;
                    }
                    &:last-child {
                        padding-right: 5px !important;
                    }
                    img {
                        width: 100%;
                        height: 100px;
                    }
                }
            }
        }
    }

    .available_room_detail {
        .property_detail_block {
            grid-template-columns: 70% 30%;
            margin-bottom: 8px;
            gap: 10px;
            @media screen and (max-width: 800px) {
                display: flex !important;
                flex-direction: column;
            }
        }

        .property_content {
            .bedroom_details {
                padding-top: 5px;
                border-top: 1px solid #C0C7D5;
                margin: 8px 0 0;
                li {
                    width: 25%;
                    &:not(:first-child) {
                        padding: 0 20px;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid #C0C7D5;
                    }
                    svg {
                        @include room_detail_svg();
                    }
                    @media screen and (max-width: 767px) {
                        width: 50%;
                        &:nth-child(odd) {
                            padding-left: 0;
                        }
                        &:nth-child(even) {
                            border-right: unset;
                        }
                    }
                    @media screen and (max-width: 380px) {
                        width: 100%;
                        padding-left: 0 !important;
                        border-right: unset !important;
                    }
                }
            }
            // padding: 15px;
            .desc_amenities{ 
                @media screen and (max-width: 992px) {
                    margin-bottom: 0;
                    margin-top: 15px !important;
                }
                h2 {
                    @media screen and (max-width: 800px) {
                        margin-bottom: 0 !important;
                    }
                }
            }
            .descrition_prop_head {
                grid-template-columns: 40% 60%;
                @media screen and (max-width: 992px) {
                    width: 100% !important;
                    display: flex !important; 
                    flex-direction: column;
                    margin-bottom: 10px !important;
                }
                .tags_block {
                    white-space: nowrap;
                }
            }

            .tags_block {
                // width: 60% !important;
                overflow: hidden;

                @media screen and (max-width: 992px) {
                    flex-direction: column;
                }

                span {
                    color: #5A6871;
                }

                .tag_heading {

                    // width: 30%;
                    // text-align: right;
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }

                .tags_container {
                    width: 76%;
                    display: flex;

                    ul {
                        width: unset;
                        flex-wrap: nowrap;

                        @media screen and (max-width: 992px) {
                            white-space: nowrap;
                            overflow-x: auto;
                            margin-left: 0 !important;
                        }

                        li {
                            font-weight: 600;
                            background: $theme-red-color;
                            color: #fff;
                            border-radius: 3px;
                            padding: 2.5px 10px;
                            margin-right: 5px;
                            font-size: 12px;

                            @media screen and (max-width: 992px) {
                                padding: 2px 8px;
                                font-size: 12px;
                            }


                            @media screen and (max-width: 992px) {
                                margin: 3px;
                            }
                        }
                    }
                    &.amenities_tag_container {
                        width: 100%;
                        ul {
                            border: unset;
                            li {
                                background: unset;
                            }
                        }
                    }
                }

                .tags_container_less_than_max {
                    li {
                        font-weight: 600;
                        background: $theme-red-color;
                        color: #fff;
                        border-radius: 3px;
                        padding: 2.5px 10px;
                        margin-right: 5px;

                        @media screen and (max-width: 992px) {
                            padding: 2px 8px;
                            font-size: 12px;
                        }

                        @media screen and (max-width: 992px) {
                            margin: 3px 0;
                        }
                    }
                }
            }

            .main_desc {
                .det_desc {
                    svg {
                        color: #7E8B93;
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    p {
                        margin-bottom: unset;
                    }

                    &:last-child {
                        p {
                            margin-bottom: 0;
                        }
                    }

                    &.food_service {
                        a {
                            color: $theme-red-color;
                        }
                    }
                }
            }

            .property_header {

                // .page_header {
                // width: 45%;
                // }
                .tags_block {

                    // width: 55%;
                    @media screen and (max-width: 992px) {
                        align-items: flex-start !important;
                    }
                }

                @media screen and (max-width: 767px) {
                    width: 74%;
                }
            }
        }

        .propertyDesc {
            padding: 10px;
            background: #EDF1F9;
            border-radius: 8px;
            .content-container.property_content {
                width: calc(100% - 30px);
            }
        }

        .red_bordered_btn {
            @include red_bordered_btn;
        }
    }

    .landing_pg .property_content .tags_block li.more_tags {
        @include more_tag;
    }

    .amenities {
        @include amenities;

        .page_header {
            margin-right: 15px;
        }

        ul {
            padding-top: 0;
            margin-bottom: 6px;
        }

        @media screen and (max-width: 992px) {
            flex-direction: column;
            overflow-x: auto;
            margin-bottom: 20px;
            align-items: flex-start !important;
        }
    }

    .property_header {
        @media screen and (max-width: 992px) {
            flex-direction: column;
            margin-bottom: 5px;
        }

        ul {
            @media screen and (max-width: 992px) {
                white-space: nowrap;
                overflow-x: auto;
            }
        }
    }

    .available_rooms_listing .property_listing .property_card {
        box-shadow: unset;
        padding: unset;
        border: unset;
    }
}

// house rules modal 
.house_rules {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
    // border: 1px solid #ed3542;
    h2 {
        font-weight: bold;
        color: #3F3F3F;
        font-size: 20px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 15px;
    }

    ul {
        padding: 15px 20px 0;
        margin-bottom: 0;
        height: 380px;
        overflow-y: auto;

        li {
            padding: 5px 0;
        }
    }
}

// Bedmatrix style
.bed_matrix_block {
    .main_bed_header {
        .bed_heading {
            font-size: 20px;
        }
        .bed_availability {
            font-size: 18px;
        }
    }
     .bed_mat_whole {
        @media screen and (max-width: 840px) {
            flex-direction: column;
            align-items: start !important;
        }
     }
    // border-bottom: 1px solid #D3D3D3;
    // border-top: 1px solid #D3D3D3;;
    .bed_matrix_header {
        @media screen and (max-width: 480px) {
            flex-direction: column;

            .page_header,
            .schedule_btns {
                width: 100%;
                margin: 5px 0;
            }

            .schedule_btns {
                button:nth(:first-child) {
                    margin-left: 0 !important;
                }
            }
        }
    }

    .bed_mat_whole {
        border-bottom: 1px solid #D3D3D3;
        border-top: 1px solid #D3D3D3;
        margin-bottom: 15px;
        padding: 10px 0;
        position: relative;
        @media screen and (max-width: 640px) {
            flex-direction: column;
        }
        @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start !important;
        }

        button {
            @media screen and (max-width: 480px) {
                // width: 100%;
            }
            @media screen and (max-width: 400px) {
                // width: 100%;
                padding: 8px 14px;
            }
        }
    }
    .content {
        @media screen and (max-width: 1300px) {
            margin: 1px 1px 1px 1px;
        }
    }

    .page_header {
        h2 {
            margin-bottom: 0;

            @media screen and (max-width: 992px) {
                display: flex;
                flex-direction: column;
            }

            .theme_color {
                @media screen and (max-width: 992px) {
                    font-size: 14px !important;
                }
            }
        }
    }

    button {
        border-radius: 5px;

        &::after,
        &::before {
            border-radius: 5px;
        }

        @media screen and (max-width: 992px) {
            font-size: 12px;
            // padding: 4px;
        }

        svg {
            @media screen and (max-width: 992px) {
                width: 15px;
            }
        }
    }

    .whole_bedmatrix {
        overflow-x: auto;

        .bm_body_cols {
            text-align: left;
            font-size: 20px;
            color: #000000;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            .bm_single_row {
                .bm__flat {
                    display: flex;
                    // min-width: 700px;
                    max-width: 700px;
                    margin-right: 6px;
                    background-color: #fff;

                    .floorBedMatrix {
                        background-color: #EBEFF7;
                        padding: 16px;

                        h5 {
                            font-size: 16px;
                            color: #181818;
                            margin-bottom: 15px;
                            font-weight: 500;

                            .flat_color {
                                color: #ED3542;
                            }

                            .floor_color {
                                color: #5A6871;
                            }
                        }
                        .fbmRoom {

                            .fbRoom_img {
                               width:25px;
                            }
                        }
                        .mainCardBlock {
                            display: flex;
                            gap: 6px;

                            // overflow-x: auto;
                            .card {
                                padding: 8px;
                                border-radius: 8px;
                                border: 1px solid #efefef;
                                box-shadow: 0px 4px 9px 0px #0000000D, 0px 16px 16px 0px #0000000A, 0px 35px 21px 0px #00000008, 0px 62px 25px 0px #00000003, 0px 97px 27px 0px #00000000;
                                min-width: 230px;
                                width: 100%;
                                margin-bottom: 5px;

                                &:first-child() {
                                    .room_ico {
                                        background-color: #FFC14A;
                                    }
                                }

                                &:nth-child(2) {
                                    .room_ico {
                                        background-color: #FF8B94;
                                    }
                                }

                                &:last-child {
                                    .room_ico {
                                        background-color: #B88DFF;
                                    }
                                }

                                // .room_ico {
                                //     color: #ffffff;
                                //     border-radius: 50%;
                                //     padding: 6px;
                                //     // height: 21px;
                                //     // width: 25px;
                                //     text-align: center;
                                //     line-height: 12px;
                                //     margin-right: 5px;

                                //     svg {
                                //         width: 14px;
                                //         margin: 0 !important;
                                //         height: unset;
                                //     }
                                // }
                                 .room_ico {
                                    color: #ffffff;
                                    background-color: #2f8e87;
                                    border-radius: 50%;
                                    padding: 2px;
                                    height: 30px;
                                    width: 30px;
                                    text-align: center;
                                    line-height: 24px;
                                    margin-right: 5px;
                                    svg {
                                        width: 16px;
                                        margin: 0 !important;
                                        height: unset;
                                    }
                                }

                                .fbmRoombed {
                                    background: #EBEFF7;
                                    padding: 5px;
                                    border-radius: 6px;
                                    margin: 2px 0;

                                    h5 {
                                        font-size: 16px;
                                        margin-right: 15px;
                                        // color: #8C8C8C;
                                        color:#3E3E3E;
                                        width: 65px;
                                    }

                                    p {
                                        font-size: 13px;
                                        margin: 0;
                                        color: #3F3F3F;
                                    }
                                }

                                .fbmRoomDetail {
                                    h5 {
                                        font-size: 14px;
                                        margin: 0;
                                    }

                                    p {
                                        color: #5C5C5C;
                                        margin: 5px 0;
                                        font-size: 13px;
                                        line-height: 15px;
                                    }

                                    svg {
                                        width: 20px;
                                    }

                                    @media screen and (max-width: 1024px) {
                                        width: 100%;
                                    }
                                }

                                .fbmRoombed {
                                    svg {
                                        width: 20px;
                                    }

                                    svg:last-child {
                                        color: #FF9CA3;
                                    }
                                }
                            }

                            @media screen and (max-width: 1024px) {
                                flex-direction: column;
                            }
                        }

                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        min-width: unset;
                        max-width: unset;
                        margin-right: 0;
                    }

                    @media screen and (max-width: 767px) {
                        min-width: 100%;
                        max-width: 100%;
                    }
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;
                }
            }
        }

        .book_btn {
            border-radius: 4px;
            font-size: 12px;
            border: unset;

            &.booked_btn {
                padding: 4px 8px;
                background: #ED3542;
            }

            &.not_booked_btn {
                border: 1px solid #C4CDDE;
                background: transparent;
                color: #6d6e6f;
                padding: 2px;
            }
        }
    }
}

.rent_amt .MuiPaper-root {
    width: 170px;
    background: #3F3F3F;
    color: #fff;

    p {
        font-size: 14px;
        padding: 5px 12px;
    }
}

.detail_booking_form {
    .form_details {
        h4 {
            font-size: 20px;
            font-weight: 500;
            color: #000;

            @media screen and (max-width:992px) {
                font-size: 16px;
            }
        }

        .MuiCard-root {
            padding: 15px;
            margin-bottom: 15px;
            box-shadow: 0 169px 47px 0 rgba(0, 0, 0, 0), 0 108px 43px 0 rgba(0, 0, 0, 0.01), 0 61px 36px 0 rgba(0, 0, 0, 0.02), 0 27px 27px 0 rgba(0, 0, 0, 0.03), 0 7px 15px 0 rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            border: 1px solid #D6D6D6;
        }

        .date_picker_field {
            .MuiStack-root {
                padding-top: 0;
            }
        }

        .MuiGrid-item {
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }

        .book_payment_btn {
            border-top: 1px solid #DFDFDF;
            padding-top: 40px;
            margin-top: 40px !important;
        }
    }

    &.schedule_form {
        .MuiCard-root {
            background-color: #F9F9F9;
            border: unset;
            box-shadow: unset;
            padding: 20px;
        }
    }
}

.hidden_tag_list {
    width: 180px;
    padding: 12px;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;

    li {
        background: #FFF0F2;
        color: #ED3542;
        padding: 3px 10px;
        margin: 3px 0;
        width: 100%;
    }
}

.more_tags {
    @include more_tag;
}

.schedule_date_time {
    .MuiStack-root {
        padding-top: 0;

        .MuiFormControl-root {
            min-width: unset;
        }
    }
}

.otp_box {
    .MuiBox-root {
        gap: 8px;

        .base-Input-root {
            input {
                box-shadow: unset;
                border-radius: 6px;
                border-color: #bfbfbf;
                height: 35px;
                width: 35px;

                @media screen and (max-width: 380px) {
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }

    a {
        color: #FAA61B !important;
    }
}


// .property_details {
//     .payment_area.book_payment_btn {
//         border-top: 1px solid #D6D6D6;
//         padding-top: 15px;
//         a {
//             color: #0075FF;
//         }
//     }
// }
.manage_enlist_property {
    .property_details {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        .payment_area.book_payment_btn {
            border-top: 1px solid #D6D6D6;
            padding-top: 15px;

            a {
                color: #0075FF !important;
            }
        }

        .img_section {
            width: 45%;
            padding-right: 40px;

            @media screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
                padding-right: 0;
            }

            img {
                max-width: 100%;
            }

            h4 {
                font-size: 20px;
                font-weight: bold;
                margin: 15px 0;
            }
        }

        .property_form_section {
            width: 55%;
            margin-bottom: 20px;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .MuiCard-root {
                border: 1px solid #D8E2E9;
                border-radius: 16px;
                box-shadow: 0 414px 116px 0 rgba(92, 112, 157, 0), 0 265px 106px 0 rgba(92, 112, 157, 0.01), 0 105px 63px 0 rgba(92, 112, 157, 0.03), 0 47px 47px 0 rgba(92, 112, 157, 0.04), 0 12px 26px 0 rgba(92, 112, 157, 0.05);
            }

            button {
                min-width: 188px;
                padding: 10px;
                border-radius: 30px;
                border: none;
            }

            h2 {
                font-size: 40px;
                font-weight: bold;
                color: #3F3F3F;

                span {
                    color: #ED3542;
                }

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            h5 {
                color: #3F3F3F;
                font-weight: bold;
                font-size: 20px;
                margin: 20px 0 10px;

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                }
            }

            .base-Input-root input {
                height: 48px;
                width: 55px;

                @media screen and (max-width: 540px) {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .custom_select {
            button {
                &.MuiButtonBase-root {
                    min-width: unset;
                }
            }
        }

        .single_input_block {
            button {
                min-width: unset !important;
            }
        }

        .custom_amenties_options {
            margin: 15px 0 0;

            li {
                background: #FFF2D8;
                padding: 4px 6px;
                margin-right: 8px;
                border-radius: 5px;
                align-items: center;
                margin-bottom: 5px;

                svg {
                    margin: 0 5px;
                    width: 18px;
                }

                .cross_ico {
                    width: 16px;
                    color: #fb0039;
                    margin-left: 10px;
                }

                .amenity_item {
                    font-size: 14px;
                }
            }
        }

        .dropzone-previews {
            .border {
                border-color: #ddd !important;
                border-radius: 5px;
            }
        }
    }
}


.custom-menu {
    background-color: #ffffff;
    /* Background color */
    color: #000000;
    /* Text color */
}

.custom-menu .pac-item {
    padding: 10px;
    /* Padding for each item */
}

.custom-menu .pac-item:hover {
    background-color: #f0f0f0;
    /* Background color on hover */
}

.custom-autocomplete {
    .MuiFormControl-root {

        // width: 100%;
        input {
            padding: 8px 0;
        }
    }

    >div {
        >div {
            display: unset;
        }
    }
}

.slider_img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
}

.main_slide_img {
    width: 100%;
    height: 177px;
    background: #ddd;
    object-fit: cover;
    border-radius: 6px;

    @media screen and (max-width: 767px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        height: 250px !important;
    }

}
.propDescBookAmenities {
    padding: 15px 0;
    .tags_block {
        .amenities_tag_container {
            ul {
                border: unset;
                @media screen and (max-width: 992px) {
                    padding-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
        margin-bottom: 0 !important;
    }
    @media screen and (max-width: 540px) {
        padding: 15px 0 5px;
    }
}
.propDescBookHeader {
    .tags_block {
        @media screen and (max-width: 992px){
            width: 100%;
        }
    }
}

/* CardSlider.css */
.slider-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // max-width: 900px;
    margin: 0 auto;
  }
  
  .slider {
    overflow: hidden;
    width: 100%;
  }
  .slider_card {
    background-color: #fdf6e4;
    padding: 10px;
    margin: 4px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    min-width: 23%;
    text-align: center;
    transition: transform 0.5s ease-in-out;
  }
  .card-container {
    display: flex;
    width: 100%;
  }
  .controls {
    display: flex;
    align-items: center;
    max-height: 20px;
    /* margin-top: 10px; */
  }
  
  button.prev, button.next {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: transparent;
  }
  
  button.prev {
    margin-right: 20px;
  }
  
  button.next {
    margin-left: 20px;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .dots {
    display: flex;
    justify-content: center;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #717171;
  }
  .testimonial-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // max-width: 900px;
    margin: 0 auto;
  }
 .testimonial_card-container {
    display: flex;
    width: 100%;
    gap: 21px !important;
 }
  .testimonial_slider {
    overflow: hidden;
    width: 100%;
  }
  .testimonial_slider_card {
    min-width: 32%;
    transition: transform 0.5s ease-in-out;
    @media screen and (max-width: 540px) {
        min-width: 100%;
    }
  }
  .header_tabpanel {
  div {
    padding: 0 !important;
  }
}
.tab_design {
    text-decoration: none !important;
    color: black !important;
    white-space: nowrap !important;
}
.tab_design:active {
    color: red !important;
}
.activeLink {
    color: red !important;
    text-decoration: none !important;
    white-space: nowrap !important;
}
.activeLink div:hover {
    background-color: transparent !important;
}
.tab_design .MuiListItemButton-root:hover {
background-color: transparent;
}
.block_heading {
    color: #3F3F3F;
    font-size: 18px;
    font-weight: 600;
}

.border {
    position: relative;
    width: 200px;
    max-width: 600px; 
    height: 42px;
    background-color: #ED3542;
    clip-path: polygon(0% 0%, 5% 50%, 0% 100%, 100% 100%, 95% 50%, 100% 0%);
   }
    
   .content {
    margin: 1px 1px 1px 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    clip-path: polygon(0% 0%, 5% 50%, 0% 100%, 100% 100%, 95% 50%, 100% 0%);
    display: flex;
    align-items: center; 
    justify-content: center; 
   }
   .zoomable-image {
    max-width: 100%;
    max-height: 100%;
    cursor: grab;
    user-select: none;
  }
  
  .zoom-controls {
    position: absolute;
    bottom: 20px;
    display: flex;
    gap: 10px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
  }
  
  .zoom-controls button {
    padding: 5px 10px;
    font-size: 18px;
    background-color: white;
    color: black;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .zoom-controls button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
.social_media_text {
    font-size: 1rem;
    color: #ffffff !important;
    text-decoration: unset !important;
}
.social_media_icon {
    a {
        color: white !important;
    }
}
.payment_modal {
    border: 1px solid #ed3542;
    border-radius: 6px;

    .total_amount_wrap {
        background-color: #FFF2DC;
        color: #e09213;
        border-radius: 6px;
    }
    .dashboard_accordion_holder {

      .MuiPaper-root::before {
        background-color: transparent;
      }

      .cashfree_accordion {
        box-shadow: none !important;
      }
      p {
        color: #737373 !important;
      }
    }
}