@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
@import "./custom.scss";


// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

.apexcharts-canvas {
  text {
    fill: #adb5bd !important;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//carousel
.carousel-custom {
  .carousel-dark {
    .carousel.slide {
      .carousel-indicators [data-bs-target] {
        background: #000;
        border-color: #fff;
      }
    }
  }
  .carousel.slide {
    .carousel-indicators [data-bs-target] {
      border: 1px solid #000;
      background: #fff;
      height: 2px;
      opacity: 0.4;
    }
    .carousel-indicators .active {
      opacity: 1;
    }
  }
}

//cursor-pointer
.cursor-pointer {
  cursor: pointer;
}

//form editor
.wysiwyg-custom {
  .rdw-link-modal {
    height: auto;
  }
  .rdw-embedded-modal-header {
    .rdw-embedded-modal-header-option {
      width: auto;
    }
  }
}

//form xeditable
.formXeditable-custom {
  button {
    margin-left: 5px;
  }
}

//User List
.dropdown-toggle {
  &:after {
    display: none !important;
  }
}

//react-table
.react_table {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }
  }
}



